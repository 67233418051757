<template>
   <modal
    :width="1150"
    :adaptive="true"
    class="tripSummaryModal CEModal modal-scroll-bar"
    name="TripSummaryModal"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>

    <h4>Vacation packages to Cairo for</h4>
    <h4>Exhibition for advertising and promotional items | 15/04/21-17/04/21</h4>
    <div class="d-flex align-items-center flex-wrap m-t-30">
      <h4 class="m-b-10">{{ $t( "TOURISM.TRIP_SUMMARY" ) }}</h4>
      <TripSummary class="mx20 m-b-10"></TripSummary>
    </div>
    <div class="row m-t-15">
      <div class="col-md-4">
        <FligntTicket></FligntTicket>
      </div>
      <div class="col-md-4">
        <FligntTicket></FligntTicket>
      </div>
      <div class="col-md-4">
        <BaggageInfo></BaggageInfo>
        <CovideNotify class="m-t-15"></CovideNotify>
      </div>
    </div>
    <p class="fs14 my20">
      4 nights in Berln then <b>depart from Willy Brandt (BER)</b>
    </p>
    <div class="row pt20">
      <div class="col-md-9">
        <HotelData></HotelData>
      </div>
      <div class="col-md-3 d-flex align-items-end">
        <button class="primary-btn primary-btn_sm bg-green6">
          {{ $t( "TOURISM.BOOK" ) }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import  FligntTicket from "./FligntTicket.vue";
import  BaggageInfo from "./BaggageInfo.vue";
import  CovideNotify from "./CovideNotify.vue";
import  TripSummary from "./TripSummary.vue";
import  HotelData from "./HotelData.vue";

export default {
  name: 'TripSummaryModal',
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary,
    HotelData
  }, 
  methods: {
    closeModal() {
      this.$modal.hide("TripSummaryModal");
    },
  }
}
</script>

<style lang="scss">
  .tripSummaryModal .vm--modal{
    padding: 20px;
  }
</style>
