<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TourismFilter></TourismFilter>
    <section class="tourism-container">
      <h3 class="m-b-15">
        {{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}
      </h3>
      <h3 class="m-b-20">
        11. - 14. January 2021 | Exhibition for advertising and promotional items
      </h3>
      <div class="d-flex align-items-center justify-content-between deal-summary">
        <h4 class="m-b-5">
          Cairo package deals
        </h4>
        <div class="d-flex fs14 m-b-5">
          <p class="mx10">
            {{ $t( "TOURISM.DATE_RANGE" ) }}: <b>01/04/2021 - 10/04/2021</b>
          </p>
          <p class="mx10">
            {{ $t( "TOURISM.DURATION" ) }}: <b>3 - 5 Nights</b> 
          </p>
          <p class="mx10">
            {{ $t( "TOURISM.TRAVELERS" ) }}: <b>2 Adults</b> 
          </p>
        </div>
        <div class="d-flex m-b-5">
          <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
          <FilterTourism></FilterTourism>
        </div>
      </div>
      <div class="row m-t-5">
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
        <div class="col-md-3">
          <TourismItem @openSummary="openTripSummaryModal">
            <template v-slot:title>
              <p class="fs14 text-center">
                <b>{{ $t('TOURISM.VACATION_PACKAGES_TO', {DES: 'Cairo'}) }}</b>
              </p>
            </template>
          </TourismItem>
        </div>
      </div>
    </section>
    <TripSummaryModal></TripSummaryModal>
  </main>
</template>

<script>
import TourismFilter from "../../../components/tourism/TourismFilter";
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";
import TourismItem from "../../../components/tourism/TourismItem";
import TripSummaryModal from "../../../components/tourism/tradeShow/TripSummaryModal.vue";
export default {
  name: "SelectedResult",
  components: {
    TourismFilter,
    FilterTourism,
    SortTourism,
    TourismItem,
    TripSummaryModal
  },
  methods: {
    sortDeals() {
    },
    openTripSummaryModal() {
      this.$modal.show("TripSummaryModal");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
@media (max-width: 600px) {
  .deal-summary {
    flex-direction: column;
  }
}
</style>