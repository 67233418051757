<template>
  <div>
    <div class="df-c p-relative card-header tourism-category__place" style="background-image: url('/assets/img/berlin2.png')">
      <h3 class="tourism-category__place-name fs23">Cairo</h3>
      <CategoryShare></CategoryShare>
      <CategoryStar></CategoryStar>
    </div>
    <div class="card-body bg-white p-b-5">
      <slot name="title"></slot>
      <FlightDesData class="m-t-10">
        <template slot="additionalData">
          <RateStar :starNumber="5"></RateStar>
          <p class="fs14"><b>Hilton hotel</b></p>
        </template>
      </FlightDesData>
      <div class="row m-t-10">
        <div class="col-md-6"></div>
        <div class="col-md-6 price-block">
          <p class="fs15">{{ $t('COMMON.PRICE') }}: <span class="fs18"><b>2596 ₪</b></span></p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <button class="primary-btn small-btn d-flex align-items-center mx5 color-primary fs16 bg-blue5" @click="openDetail">
          <img src="/assets/img/icons/info.svg" alt="" class="mx5">
          {{ $t('CART.DETAILS') }}
        </button>
        <button class="primary-btn small-btn mx5 bg-green7 fs16">
          {{ $t('TOURISM.CHOOSE_VACATION_DEALS') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../RateStar';
import CategoryShare from '../CategoryShare';
import CategoryStar from './CategoryStar';
import FlightDesData from './FlightDesData';
export default {
  name: "TourismItem",
  components: {
    RateStar,
    CategoryShare,
    CategoryStar,
    FlightDesData
  },
  methods: {
    openDetail() {
      this.$emit("openSummary");
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    height: 180px;
    background-size: cover;
  }
  .fs16 {
    font-size: 16px !important;
  }

  .rtl-type {
    .price-block {
      text-align: left;
      padding-left: 25px;
    }
  }
</style>